<template>
  <a-menu-item
    v-if="!menu.children || menu.children.length === 0"
    :key="menu.path"
  >
    <template v-if="menu.icon" #icon>
      <svg-icon v-if="isSvgIcon(menu.icon)" :icon-class="menu.icon"></svg-icon>
      <component v-else :is="$antIcons[menu.icon]"></component>
    </template>
    <span>{{ menu.title }}</span>
  </a-menu-item>
  <a-sub-menu v-else :key="menu.path">
    <template #icon>
      <svg-icon v-if="isSvgIcon(menu.icon)" :icon-class="menu.icon"></svg-icon>
      <component v-else :is="$antIcons[menu.icon]"></component>
    </template>
    <template #title>
      {{ menu.title }}
    </template>
    <menu-item
      v-for="(item, idx) in menu.children"
      :key="idx"
      :menu="item"
    ></menu-item>
  </a-sub-menu>
</template>

<script>
import { toRefs } from "vue";
export default {
  name: "MenuItem",
  props: ["menu"],
  setup(props) {
    const isSvgIcon = icon => {
      return icon.indexOf("svg") > -1;
    };
    return {
      ...toRefs(props),
      isSvgIcon,
    };
  },
};
</script>

<style></style>
