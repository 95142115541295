<template>
  <div id="sidebar" :class="[isCollapsed ? 'collapsed' : '']">
    <div id="header">
      <a-image :src="AJBlogo" height="35px"></a-image>
    </div>
    <div style="max-height: 100%; overflow-y: auto">
      <a-menu
        id="menu"
        :selected-keys="[$route.path]"
        :open-keys="openedKeys"
        mode="inline"
        theme="dark"
        :inline-collapsed="isCollapsed"
        @click="clickMenu"
      >
        <menu-item
          v-for="(item, idx) in $store.state.sidebar.menus"
          :key="idx"
          :menu="item"
        ></menu-item>
      </a-menu>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import AJBlogo from "@/assets/AJBlogo.png";
import { computed, reactive } from "vue";
import { useRoute } from "vue-router";
import MenuItem from "@/layouts/Sidebar/components/MenuItem";
import router from "@/router";
import { getParentPath } from "@/utils/routerUtils";
export default {
  name: "index",
  setup() {
    const isCollapsed = computed(() => {
      return store.state.sidebar.isCollapsed;
    });
    const clickMenu = item => {
      router.push({
        path: item.key,
      });
    };
    const route = useRoute();
    let list = [];
    getParentPath(route.path, list);
    const openedKeys = reactive(list);

    return {
      isCollapsed,
      clickMenu,
      openedKeys,
      AJBlogo,
    };
  },
  components: {
    MenuItem,
  },
};
</script>

<style scoped>
.sidebar-menu {
  height: 100vh;
}
</style>
